@font-face {
  src: url("./fonts/Neon.ttf");
  font-family: "Neon";
}

@font-face {
  src: url("./fonts/Streamster.ttf");
  font-family: "Streamster";
}

@font-face {
  src: url("./fonts/barcade.ttf");
  font-family: "Barcade";
}

@font-face {
  src: url("./fonts/Outrun.otf");
  font-family: "Outrun";
}

#main {
  color: white;
}

#header {
  font-family: "Neon";
  min-height: 47vh;
}

h1 {
  font-family: "Streamster";
  font-size: calc(72px + 0.7vw);
  padding: 15px;
  color: rgba(255, 0, 0, 0.8) !important;
}

h3 {
  font-family: "Streamster";
  padding-top: 5px;
  padding-bottom: 30px;
  font-size: calc(28px + 1.2vw);
}

.col {
  /* border: 1px solid black; */
  padding-top: 5px;
  font-size: calc(10px + 2vw);
  text-align: center;
  transition: 0.35s;
}

.col .options :hover {
  text-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 7px rgba(255, 0, 0, 1),
    0 0 10px rgba(255, 0, 0, 1), 0 0 20px rgba(255, 0, 0, 1),
    0 0 25px rgba(255, 0, 0, 1), 0 0 35px rgba(255, 0, 0, 1);
}

.jumbotron {
  min-height: 50vh;

  background-color: rgba(0, 0, 0, 0);
  /* background-image: url("/images/DSC09023-2.jpg");
  background-size: cover;
  background-repeat: no-repeat; */
}

.modal-content {
  background-color: rgba(11, 17, 17, 0.7);
  color: #fff;
}

.list-group-item {
  background-color: rgba(11, 17, 17, 0);
  color: #fff;
  text-align: center;
}

.close {
  color: rgba(255, 255, 255, 0.7);
}

.options {
  cursor: pointer;
  font-size: 2.5rem;
}

#blog {
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 430px) {
  .options {
    cursor: pointer;
    font-size: 1rem;
  }
}
