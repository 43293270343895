.allCards {
  display: inline-flex;
  float: left;
  flex-wrap: wrap;
  max-width: 500px;
}

.contact {
  /* margin: 0; */
  justify-content: center;
  /* padding: 5px;
  margin: 5px; */
  color: white;
  background-color: rgba(0, 0, 0, 0);
}
